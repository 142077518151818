import "../App.css";
import "../styles/confirmation.css";
import React, { useState, useEffect } from "react";
import envelope from "../assets/img/envelope.svg";
import iconImage from "../assets/img/wooblly-logo.svg";
import successImage from "../assets/img/envelope-open.svg";
import { useTranslation } from "react-i18next";
import ConfettiExplosion from "react-confetti-explosion";
import "../i18n";

const ConfirmationPage = () => {
  const { t } = useTranslation("global");
  const [imageSrc, setImageSrc] = useState(envelope);
  const [isContentVisible, setIsContentVisible] = useState(false);

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const updateStates = () => {
    setTimeout(() => {
      setImageSrc(successImage);
      setIsContentVisible(true);
    }, 500);
  };

  useEffect(() => {
    const token = getQueryParam("confirmation-token");
    if (token) {
      const apiUrl = `${process.env.REACT_APP_WOOBLLY_BASE_URL}/api/v1/authentication/confirm-account?token=${token}`;
      fetch(apiUrl)
        .then((response) => {
          if (response.ok) {
            updateStates();
          }
        })
        .catch(() => {
          alert(t("something_went_wrong"));
        });
    }
  }, [t]);

  return (
    <div className="envelope-container">
      <div>
        <img src={imageSrc} alt="Logo" className="envelope-img" />
        {isContentVisible && (
          <>
            <div className="envelope-content">
              <img src={iconImage} alt="Icon" className="icon-image" />
              <h1>{t("verify_email")}</h1>
              <p>{t("verify_email_message")}</p>
            </div>
            <div className="animation-fullscreen">
              <ConfettiExplosion
                force={0.8}
                duration={3500}
                particleCount={350}
                width={2800}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmationPage;
