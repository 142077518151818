import { useTranslation } from "react-i18next";
import "../App.css";
import "../i18n";

const TermsAndConditionsPage = () => {
  const { t } = useTranslation("global");

  return (
    <div className="terms-and-conditions-container">
      <section>
        <h3>1. {t("terms_condtions_intro_title")}</h3>
        <p>{t("terms_condtions_intro_text_1")}</p>
        <p>{t("terms_condtions_intro_text_2")}</p> 
      </section>
      <section>
        <h3>2. {t("terms_condtions_service_description_title")}</h3>
        <p>{t("terms_condtions_service_description_text_1")}</p>
      </section>
      <section>
        <h4>3. {t("terms_condtions_service_usage_title")}</h4>
        <p>{t("terms_condtions_service_usage_text")}</p>
        <ul>
          <li>{t("terms_condtions_service_usage_ul_1")}</li>
          <li>{t("terms_condtions_service_usage_ul_2")}</li>
          <li>{t("terms_condtions_service_usage_ul_3")}</li>
        </ul>
        <p />
      </section>
      <section>
        <h3>4. {t("terms_condtions_extra_terms_title")}</h3>
        <p>{t("terms_condtions_extra_terms_text")}</p>
      </section>
      <section>
        <h3>5. {t("terms_condtions_account_title")}</h3>
        <p>{t("terms_condtions_extra_terms_text_1")}</p>
        <p>{t("terms_condtions_extra_terms_text_2")}</p> 
        <p>{t("terms_condtions_extra_terms_text_3")}</p> 
        <ul>
          <li>{t("terms_condtions_extra_terms_ul_1")}</li>
          <li>{t("terms_condtions_extra_terms_ul_2")}</li>
          <li>{t("terms_condtions_extra_terms_ul_3")}</li>
          <li>{t("terms_condtions_extra_terms_ul_4")}</li>
          <li>{t("terms_condtions_extra_terms_ul_5")}</li>
        </ul>
        <p/>
      </section>
      <section>
        <h3>6. {t("terms_condtions_profile_title")}</h3>
        <p>{t("terms_condtions_profile_text_1")}</p>
        <p>{t("terms_condtions_profile_text_2")}</p> 
      </section>
      <section>
        <h3>7. {t("terms_condtions_underage_account_title")}</h3>
        <p>{t("terms_condtions_underage_account_text")}</p>
      </section>
      <section>
        <h3>8. {t("terms_condtions_handling_data_title")}</h3>
        <p>{t("terms_condtions_handling_data_text")}</p>
      </section>
      <section>
        <h3>9. {t("terms_condtions_user_obligation_title")}</h3>
        <p>{t("terms_condtions_user_obligation_text")}</p>
      </section>
      <section>
        <h3>10. {t("terms_condtions_data_share_title")}</h3>
        <p>{t("terms_condtions_data_share_text_1")}</p>
        <p>{t("terms_condtions_data_share_text_2")}</p> 
      </section>
      <section>
        <h3>11. {t("terms_condtions_wooblly_payment_title")}</h3>
        <p>{t("terms_condtions_wooblly_payment_text")}</p>
        <ul>
          <li>{t("terms_condtions_wooblly_payment_ul_1")}</li>
          <li>{t("terms_condtions_wooblly_payment_ul_2")}</li>
          <li>{t("terms_condtions_wooblly_payment_ul_3")}</li>
          <li>{t("terms_condtions_wooblly_payment_ul_4")}</li>
        </ul>
        <p/>
      </section>
      <section>
        <h3>12. {t("terms_condtions_app_licence_title")}</h3>
        <p>{t("terms_condtions_app_licence_text_1")}</p>
        <ul>
          <li>{t("terms_condtions_app_licence_ul_1")}</li>
          <li>{t("terms_condtions_app_licence_ul_2")}</li>
          <li>{t("terms_condtions_app_licence_ul_3")}</li>
        </ul>
        <p>{t("terms_condtions_app_licence_text_2")}</p> 
      </section>
      <section>
        <h3>13. {t("terms_condtions_in_app_puchase_title")}</h3>
        <p>{t("terms_condtions_in_app_puchase_text_1")}</p>
        <p>{t("terms_condtions_in_app_puchase_text_2")}</p> 
      </section>
      <section>
        <h3>14. {t("terms_condtions_intelectual_property_title")}</h3>
        <p>{t("terms_condtions_intelectual_property_text")}</p>
      </section>
      <section>
        <h3>15. {t("terms_condtions_third_party_title")}</h3>
        <p>{t("terms_condtions_third_party_text")}</p>
      </section>
      <section>
        <h3>16. {t("terms_condtions_responsabilities_title")}</h3>
        <p>{t("terms_condtions_responsabilities_text")}</p>
      </section>
      <section>
        <h3>17. {t("terms_condtions_usage_ending_title")}</h3>
        <p>{t("terms_condtions_usage_ending_text")}</p>
      </section>
      <section>
        <h3>18. {t("terms_condtions_contact_title")}</h3>
        <p>{t("terms_condtions_contact_text")}</p>
      </section>
    </div>
  );
};

export default TermsAndConditionsPage;
