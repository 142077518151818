import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import iconImage from "../assets/img/wooblly-logo.svg";
import "../App.css";
import "../i18n";

const SetPasswordPage = () => {
  const { t } = useTranslation("global");
  const baseUrl = process.env.REACT_APP_WOOBLLY_BASE_URL;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert(t("passwords_do_not_match"));
      return;
    }
    var token = getQueryParam("confirmation-token");
    if (token) {
      const requestBody = {
        password: password,
        confirmationToken: token,
      };
      resetPassword(requestBody);
    }
  };

  const resetPassword = async (requestBody) => {
    try {
      const apiUrl = `${baseUrl}/api/v1/authentication/reset-password`;
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.message || t("something_went_wrong"));
        return;
      }
      const data = await response.json();
      alert(t("password_reset_successful"));
    } catch (error) {
      alert(t("something_went_wrong"));
    }
  };

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const updateStates = () => {
    setTimeout(() => {}, 500);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="password-reset-container">
      <div className="logo">
        <img src={iconImage} alt="Woobbly Logo" />
      </div>
      <h1>{t("set_new_password")}</h1>
      <p>{t("reset_passoword")}</p>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="password">{t("password")}</label>
          <input
            id="password"
            type={showPassword ? "text" : "password"}
            placeholder={t("password_placeholder")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="toggle-visibility"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? "🙈" : "👁️"}
          </span>
        </div>
        <div className="input-group">
          <label htmlFor="confirm-password">{t("confirm_password")}</label>
          <input
            id="confirm-password"
            type={showConfirmPassword ? "text" : "password"}
            placeholder={t("confirm_password_placeholder")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span
            className="toggle-visibility"
            onClick={toggleConfirmPasswordVisibility}
          >
            {showConfirmPassword ? "🙈" : "👁️"}
          </span>
        </div>
        <p className="password-requirements">
          {t("password_constraints_info")}:
          <ul>
            <li>8 {t("characters")}</li>
            <li>1 {t("capital_letter")}</li>
            <li>1 {t("small_letter")}</li>
            <li>1 {t("number")}</li>
          </ul>
        </p>
        <button type="submit" className="set-password-btn">
          <span>{t("set_password")}</span>
        </button>
      </form>
    </div>
  );
};

export default SetPasswordPage;
