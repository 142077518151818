import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import global_en from "./locales/en/global.json";
import global_ro from "./locales/ro/global.json";

const availableLanguages = ["en", "ro"];

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    whitelist: availableLanguages,

    detection: {
      checkWhitelist: true,
    },

    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        global: global_en,
      },
      ro: {
        global: global_ro,
      },
    },
  });

export default i18n;
